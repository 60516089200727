import classNames from "classnames";
import Image from "next/image";
import Link from "next/link";
import { API } from "../../services/api/types";
import { humanizeDate } from "../../utils/date";
import { createImageSizes } from "../../utils/image";
import styles from "../ArticleItem/styles.module.css";
import Placeholder from "../Placeholder";

type RSSItemProps = {
  item: API.RSSItem;
};

const RSSItem = (props: RSSItemProps) => {
  const image = props.item["media:thumbnail"]["@_url"];

  return (
    <Link href={props.item.link} passHref>
      <a>
        <article
          className={classNames([
            styles.article,
            "cursor-pointer",
            "flex",
            "gap-5",
          ])}
        >
          {image ? (
            <div
              className={classNames([
                "max-w-[80px]",
                "relative",
                "flex-1",
                "h-[80px]",
              ])}
            >
              <Image
                layout="fill"
                unoptimized
                sizes={createImageSizes({
                  mobile: "15vw",
                  tablet: "15vw",
                  desktop: "15vw",
                })}
                className="object-cover"
                src={image}
                alt="Artikelbild" // TODO: Get image description from DB
              />
            </div>
          ) : (
            <Placeholder width={80} height={80} />
          )}
          <div className={classNames(["flex", "flex-col", "flex-1"])}>
            <h2
              className={classNames([
                "text-base",
                "font-bold",
                "text-text-primary",
                "line-clamp-2",
                "mb-2",
                "pb-[0.04em]",
              ])}
            >
              {props.item.title}
            </h2>

            <div className={classNames(["mt-auto"])}>
              <span className={classNames("text-gray-400", "text-sm")}>
                {humanizeDate(props.item.pubDate, {
                  later: { excludeTime: true },
                })}
              </span>
            </div>
          </div>
        </article>
      </a>
    </Link>
  );
};

export default RSSItem;

import classNames from "classnames";
import Link from "next/link";
import { FC } from "react";
import AdsIds from "../../../../data/ads";
import { API } from "../../../../services/api/types";
import * as TransferWindow from "../../../../utils/TransferWindow";
import Advertisement from "../../../Advertisement";
import ArticleItem from "../../../ArticleItem";
import ArticlePush from "../../../ArticleItem/ArticlePush";
import RSSItem from "../../../RSSItem";
import SectionHeader from "../../../SectionHeader";

type SecondaryFeedDesktopProps = {
  latestNews: API.Article[];
  mostViewedNews: API.Article[];
  transferWindow?: API.TransferWindow;
  hockeynewsRssArticles?: API.RSSItem[];
};

const SecondaryFeed: FC<SecondaryFeedDesktopProps> = (props) => {
  return (
    <>
      <SectionHeader label="Senaste nytt" />
      {props.latestNews.slice(0, 5).map((article, index) => {
        return (
          <div key={article.id} className={classNames(["py-5", "border-b"])}>
            <ArticleItem type={"small"} article={article} />
          </div>
        );
      })}

      <div className={classNames(["my-5"])}>
        <Advertisement id={AdsIds.tws_insider} />
      </div>

      <Advertisement id={AdsIds.desktop_textlink_top} />
      <div className={classNames(["mb-5"])} />

      <ArticlePush
        href="/nyheter/nar-oppnar-och-stanger-transferfonstret/1468"
        text="När öppnar och stänger transferfönstret? Se alla datum för 93 länder."
        bubbleText={TransferWindow.getText(
          new Date(),
          new Date(props?.transferWindow?.open || 0),
          new Date(props?.transferWindow?.close || 0),
          TransferWindow.SWEDISH
        )}
        imageSrc={`${process.env.NEXT_PUBLIC_ASSET_SERVER_URL}/uploads/images/news/1580518483/528x300.jpg`}
        imageAlt="Transferfönstret"
      />
      <div className={classNames(["mb-5"])} />
      <Advertisement id={AdsIds.desktop_textlink_1} />

      <div className={classNames(["mt-5"])} />
      <SectionHeader label="Mest lästa" />
      {props.mostViewedNews.slice(0, 5).map((article) => {
        return (
          <div key={article.id} className={classNames(["py-5", "border-b"])}>
            <ArticleItem type={"small"} article={article} />
          </div>
        );
      })}
      <div className={classNames(["mb-5"])} />
      <Advertisement id={AdsIds.desktop_textlink_2} />
      <div className={classNames(["mb-5"])} />
      <ArticlePush
        href="/spelare/dyraste-spelarna"
        text="Dyraste spelarna i Sverige och Europa - se hela listan!"
        imageSrc="/push/expensive.jpg"
        imageAlt="Spelare"
      />
      <div className={classNames(["mb-5"])} />
      <Advertisement id={AdsIds.desktop_textlink_3} />
      <div className={classNames(["mb-5"])} />
      <ArticlePush
        href="/nyheter/utbildningsbidrag-och-solidaritetsersattning/4896"
        text="Allt du behöver veta om utbildningsbidrag och solidaritetsersättning."
        imageSrc="/push/edu.jpg"
        imageAlt="Spelare"
      />
      <div className={classNames(["mb-5"])} />
      <Advertisement id={AdsIds.desktop_textlink_4} />
      <div className={classNames(["mb-5"])} />
      <SectionHeader label="Nytt från hockeyvärlden" />
      {props.hockeynewsRssArticles?.map((article) => {
        return (
          <div key={article.guid} className={classNames(["py-5", "border-b"])}>
            <RSSItem item={article} />
          </div>
        );
      })}

      <div className="flex justify-center my-5">
        <Link passHref href="https://hockeynews.se">
          <a className="font-bold">Läs fler nyheter på HockeyNews.se</a>
        </Link>
      </div>
    </>
  );
};

export default SecondaryFeed;

import classNames from "classnames";
import Link from "next/link";
import { FC } from "react";
import InfiniteScroll from "react-infinite-scroller";
import AdsIds from "../../../../data/ads";
import { getLeagueMenu } from "../../../../data/leagues";
import { API } from "../../../../services/api/types";
import { getDynamicMobileTagId } from "../../../../utils/ads";
import * as TransferWindow from "../../../../utils/TransferWindow";
import Advertisement from "../../../Advertisement";
import ArticleItem from "../../../ArticleItem";
import ArticlePush from "../../../ArticleItem/ArticlePush";
import BreakingTeaser from "../../../BreakingTeaser";
import { BreakingTeaser as BreakingTeaserType } from "../../../BreakingTeaser/types";
import Container from "../../../Container";
import RSSItem from "../../../RSSItem";
import SectionHeader from "../../../SectionHeader";
import SectionMenuButton from "../../../SectionMenu/SectionMenuButton";
import VideoPlayer from "../../../VideoPlayer";

type MainFeedMobileProps = {
  news: API.Article[];
  latestNews: API.Article[];
  category: FixMeLater;
  breakingTeasers: BreakingTeaserType[];
  onReachEnd: () => void;
  onClickCategory: (category: FixMeLater, index: number) => void;
  transferWindow?: API.TransferWindow;
  oneArticle?: API.Article;
  hockeynewsRssArticles?: API.RSSItem[];
};

const MainFeedMobile: FC<MainFeedMobileProps> = (props) => {
  const newsItemIndexToAdId: { [key: number]: number } = {
    4: 91,
    8: 92,
    12: 93,
    16: 94,
  };

  const renderPrimaryArticle = (article: API.Article, index: number) => {
    const regularAdIndex = index - 16;
    const isShowingSpecialAd =
      index % 4 === 0 && index > 0 && regularAdIndex <= 0;
    const isShowingRegularAd = regularAdIndex % 8 === 0 && regularAdIndex > 0;
    const type = index % 4 === 0 ? "large" : "small";

    const adId = isShowingRegularAd
      ? AdsIds.dynamic_mobile
      : newsItemIndexToAdId[index];

    return (
      <div key={article.id} className={classNames(["py-5", "border-b"])}>
        {(isShowingSpecialAd || adId) && (
          <div className={classNames("mb-5")}>
            <Advertisement
              id={adId}
              codeReplaceAll={() => ({ tagId: getDynamicMobileTagId() })}
            />
          </div>
        )}
        <ArticleItem type={type} article={article} />
      </div>
    );
  };

  const renderSecondaryArticle = (article: API.Article, index: number) => {
    return (
      <div key={article.id} className={classNames(["py-5", "border-b"])}>
        <ArticleItem hideImage type={"small"} article={article} />
      </div>
    );
  };

  const renderRssArticle = (article: API.RSSItem) => {
    return (
      <div key={article.guid} className={classNames(["py-5", "border-b"])}>
        <RSSItem item={article} />
      </div>
    );
  };

  return (
    <InfiniteScroll hasMore={true} pageStart={0} loadMore={props.onReachEnd}>
      {props.breakingTeasers.map((breakingTeaser, index) => {
        const isLast = index === props.breakingTeasers.length - 1;

        return (
          <div
            key={breakingTeaser.link}
            className={classNames({ "border-b": !isLast }, "py-2")}
          >
            <BreakingTeaser teaser={breakingTeaser} />
          </div>
        );
      })}
      <div className={classNames("mb-3")} />
      <Container horizontalMargin opposite>
        <SectionHeader
          label="Kategori:"
          menu={
            <SectionMenuButton
              activeItem={props.category.index}
              items={getLeagueMenu().map((league, index) => {
                return {
                  label: league.title,
                  onClick: () => props.onClickCategory(league, index),
                };
              })}
            />
          }
        />
      </Container>
      {props.oneArticle && (
        <>
          <div className={classNames(["py-5", "border-b"])}>
            <ArticleItem type={"large"} article={props.oneArticle} />
          </div>

          <div className={classNames("mt-5")}>
            <Advertisement
              id={AdsIds.dynamic_mobile}
              codeReplaceAll={() => ({ tagId: getDynamicMobileTagId() })}
            />
          </div>
        </>
      )}
      {props.news.slice(0, 4).map(renderPrimaryArticle)}

      <Container horizontalMargin opposite>
        <SectionHeader label="Video" />
      </Container>

      <div className="my-5">
        <VideoPlayer id="AAFqTWtkmWIsIgcc" />
      </div>

      <div className={classNames("my-5")}>
        <Advertisement
          id={AdsIds.dynamic_mobile}
          codeReplaceAll={() => ({ tagId: getDynamicMobileTagId() })}
        />
      </div>
      <Container horizontalMargin opposite>
        <SectionHeader label="Senaste nytt" />
      </Container>
      {props.latestNews.slice(0, 5).map(renderSecondaryArticle)}
      <div className={classNames("my-5")}>
        <Advertisement id={AdsIds.mobile_native} />
      </div>
      {props.news.slice(4, 8).map(renderPrimaryArticle)}
      <div className={classNames("my-5")}>
        <Advertisement id={AdsIds.mobile_vi} />
      </div>
      <ArticlePush
        href="/nyheter/nar-oppnar-och-stanger-transferfonstret/1468"
        text="När öppnar och stänger transferfönstret? Se alla datum för 93 länder."
        bubbleText={TransferWindow.getText(
          new Date(),
          new Date(props?.transferWindow?.open || 0),
          new Date(props?.transferWindow?.close || 0),
          TransferWindow.SWEDISH
        )}
        imageSrc={`${process.env.NEXT_PUBLIC_ASSET_SERVER_URL}/uploads/images/news/1580518483/528x300.jpg`}
        imageAlt="Transferfönstret"
      />
      <div className={classNames("mb-5")} />
      <Advertisement id={AdsIds.mobile_textlink_top} />
      <div className={classNames("mb-5")} />
      <Container horizontalMargin opposite>
        <SectionHeader label="Nytt från hockeyvärlden" />
      </Container>
      {props.hockeynewsRssArticles?.map(renderRssArticle)}
      <div className="flex justify-center mt-5">
        <Link passHref href="https://hockeynews.se">
          <a className="font-bold">Läs fler nyheter på HockeyNews.se</a>
        </Link>
      </div>
      {props.news.slice(8).map(renderPrimaryArticle)}
    </InfiniteScroll>
  );
};

export default MainFeedMobile;
